export const MINIMUM_SEARCH_LENGTH = 3;

export const TYPENAME_BOARD = 'Board';
export const TYPENAME_CI_BUILD = 'Ci::Build';
export const TYPENAME_CI_TRIGGER = 'Ci::Trigger';
export const TYPENAME_CI_PIPELINE = 'Ci::Pipeline';
export const TYPENAME_CI_RUNNER = 'Ci::Runner';
export const TYPENAME_CI_VARIABLE = 'Ci::Variable';
export const TYPENAME_COMMIT_STATUS = 'CommitStatus';
export const TYPENAME_CONTAINER_REPOSITORY = 'ContainerRepository';
export const TYPENAME_CRM_CONTACT = 'CustomerRelations::Contact';
export const TYPENAME_CRM_ORGANIZATION = 'CustomerRelations::Organization';
export const TYPENAME_DESIGN_VERSION = 'DesignManagement::Version';
export const TYPENAME_DISCUSSION = 'Discussion';
export const TYPENAME_EPIC = 'Epic';
export const TYPENAME_EPIC_BOARD = 'Boards::EpicBoard';
export const TYPENAME_FEATURE_FLAG = 'FeatureFlag';
export const TYPENAME_GROUP = 'Group';
export const TYPENAME_ISSUE = 'Issue';
export const TYPENAME_ITERATION = 'Iteration';
export const TYPENAME_ITERATIONS_CADENCE = 'Iterations::Cadence';
export const TYPENAME_LABEL = 'Label';
export const TYPENAME_MEMBER_ROLE = 'MemberRole';
export const TYPENAME_MERGE_REQUEST = 'MergeRequest';
export const TYPENAME_MILESTONE = 'Milestone';
export const TYPENAME_NOTE = 'Note';
export const TYPENAME_PACKAGES_PACKAGE = 'Packages::Package';
export const TYPENAME_PROJECT = 'Project';
export const TYPENAME_SCANNER_PROFILE = 'DastScannerProfile';
export const TYPENAME_SITE_PROFILE = 'DastSiteProfile';
export const TYPENAME_TODO = 'Todo';
export const TYPENAME_USER = 'User';
export const TYPENAME_VULNERABILITY = 'Vulnerability';
export const TYPENAME_WORK_ITEM = 'WorkItem';
export const TYPE_ORGANIZATION = 'Organizations::Organization';
export const TYPE_USERS_SAVED_REPLY = 'Users::SavedReply';
export const TYPE_WORKSPACE = 'RemoteDevelopment::Workspace';
export const TYPE_COMPLIANCE_FRAMEWORK = 'ComplianceManagement::Framework';

export const QUERY_PARAM_START_CURSOR = 'start_cursor';
export const QUERY_PARAM_END_CURSOR = 'end_cursor';
